import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { MdAdd, MdEdit } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import Button from "src/components/Buttons/Button";
import Card from "src/components/Cards/Card";
import CardHeader from "src/components/Cards/CardHeader";
import ContentBody from "src/components/Layout/ContentBody";
import MainContent from "src/components/Layout/MainContent";
import RightContent from "src/components/Layout/RightContent";
import ListItemText from "src/components/Lists/ListItemText";
import Placeholder from "src/components/Placeholder/Placeholder";
import Tab from "src/components/Tabs/Tab";
import TabNavigation from "src/components/Tabs/TabNavigation";
import adjustEndTimeByAddingDayIfBeforeStart from "src/features/datetime/utils/adjustEndTimeByAddingDayIfBeforeStart";
import combineDateWithTime from "src/features/datetime/utils/combineDateWithTime";
import formatTime from "src/features/datetime/utils/formatTime";
import DiscussionContainer from "src/features/discussions/components/DiscussionContainer";
import getFullName from "src/features/employees/utils/getFullName";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import useCopyLastWeeklyScheduleMutation from "../hooks/useCopyLastWeeklyScheduleMutation";
import useCreateShiftRequirementMutation from "../hooks/useCreateShiftRequirementMutation";
import useGetEmployeeScheduleRolesQuery from "../hooks/useGetEmployeeScheduleRolesQuery";
import useGetEmployeeShiftsByDepartmentIdQuery from "../hooks/useGetEmployeeShiftsByDepartmentIdQuery";
import useGetOrCreateWeeklySchedule from "../hooks/useGetOrCreateWeeklySchedule";
import useGetScheduleRolesQuery from "../hooks/useGetScheduleRolesQuery";
import useGetShiftRequirementsByScheduleIdQuery from "../hooks/useGetShiftRequirementsByScheduleIdQuery";
import useGetShiftTypesQuery from "../hooks/useGetShiftTypesQuery";
import useGetTimeOffRequestsByStartDateQuery from "../hooks/useGetTimeOffRequestsByStartDateQuery";
import usePublishWeeklyScheduleMutation from "../hooks/usePublishWeeklyScheduleMutation";
import useWeekFromUrl from "../hooks/useWeekFromUrl";
import {
  ShiftRequirementStatus,
  ShiftRequirementType,
} from "../services/shiftRequirementsService";
import { ShiftType } from "../services/shiftTypeService";
import {
  TimeOffRequest,
  TimeOffStatus,
} from "../services/timeOffRequestService";
import {
  default as ScheduleCalendarBox,
  default as ShiftRequirementBox,
} from "./ScheduleCalendarBox";
import ScheduleCalenderHeader from "./ScheduleCalenderHeader";
import ScheduleKeyRow from "./ScheduleKeyRow";
import SelectEmployeeScheduleRoleDialog from "./SelectEmployeeScheduleRoleDialog";
import ShiftRequirementPlanningDialog from "./ShiftRequirementPlanningDialog";
import ShiftTypeFormDialog from "./ShiftTypeFormDialog";
import TimeOffBox from "./TimeOffBox";
import TimeOffRequestDecisionDialog from "./TimeOffRequestDecisionDialog";

export type ShiftRequirementDetails = {
  employeeId: number;
  departmentId: number;
  name: string;
  weeklyScheduleId: number;
  startDateTime: string;
  endDateTime: string;
};

export default function SchedulerCalendar() {
  const { id } = useParams();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { startOfWeekDate, endOfWeekDate, weekDates, handleNavigateWeek } =
    useWeekFromUrl();

  const [
    selectEmployeeScheduleRoleDetails,
    setSelectEmployeeScheduleRoleDetails,
  ] = useState<ShiftRequirementDetails | null>(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [shift, setShift] = useState<ShiftRequirementType | null>(null);
  const [isShiftTypeDialogOpen, setIsShiftTypeDialogOpen] = useState(false);
  const [selectedShiftType, setSelectedShiftType] = useState<ShiftType | null>(
    null
  );
  const [isShiftRequirementDialogOpen, setIsShiftRequirementDialogOpen] =
    useState(false);
  const [isEmployeeRoleDialogOpen, setIsEmployeeRoleDialogOpen] =
    useState(false);
  const [selectedTimeOffRequest, setSelectedTimeOffRequest] =
    useState<TimeOffRequest | null>(null);
  const [isTimeOffRequestDialogOpen, setIsTimeOffRequestDialogOpen] =
    useState(false);

  const { data: timeOffRequests } = useGetTimeOffRequestsByStartDateQuery({
    startDate: startOfWeekDate.toISOString().slice(0, 10),
    endDate: endOfWeekDate.toISOString().slice(0, 10),
    status: TimeOffStatus.Approved,
  });

  const { data: employeeScheduleRoles } = useGetEmployeeScheduleRolesQuery({
    departmentId: id ? Number(id) : undefined,
  });

  const distinctEmployeeScheduleRoles = useMemo(() => {
    if (!employeeScheduleRoles) return [];

    const employeeMap = new Map();

    employeeScheduleRoles.forEach((role) => {
      if (!employeeMap.has(role.employee.id)) {
        employeeMap.set(role.employee.id, role);
      }
    });

    return Array.from(employeeMap.values());
  }, [employeeScheduleRoles]);

  const { data: scheduleRoles, isLoading: isScheduleRolesLoading } =
    useGetScheduleRolesQuery({ departmentId: id });

  const { data: shiftTypes, isLoading: isShiftTypesLoading } =
    useGetShiftTypesQuery({ department_id: id });

  const { data: weeklySchedule } = useGetOrCreateWeeklySchedule({
    departmentId: Number(id),
    startDate: format(startOfWeekDate, "yyyy-MM-dd"),
    endDate: format(endOfWeekDate, "yyyy-MM-dd"),
  });

  const { data: employeeShifts } = useGetEmployeeShiftsByDepartmentIdQuery({
    departmentId: id ? Number(id) : undefined,
    weeklyScheduleId: weeklySchedule?.id,
  });

  const { data: shiftRequirements, isLoading: isShiftRequirementsLoading } =
    useGetShiftRequirementsByScheduleIdQuery({
      weeklyScheduleId: weeklySchedule?.id,
    });

  const createShiftRequirementMutation = useCreateShiftRequirementMutation({
    onSuccess: (data) => {
      setShift(data);
      setIsShiftRequirementDialogOpen(true);
    },
  });

  const publishWeeklyScheduleMutation = usePublishWeeklyScheduleMutation();

  const copyLastWeeklyScheduleMutation = useCopyLastWeeklyScheduleMutation({
    onSuccess: () => {
      alert.success("Last week's schedule copied successfully");
      setIsFilterVisible(false);
      queryClient.invalidateQueries([
        QueryKeys.ShiftRequirementsList,
        weeklySchedule?.id,
      ]);
      queryClient.invalidateQueries([QueryKeys.EmployeeShiftsList]);
    },
  });

  const handlePublishWeeklySchedule = () => {
    if (!weeklySchedule) {
      alert.error("Weekly schedule ID not found. Please refresh the page.");
      return;
    }
    publishWeeklyScheduleMutation.mutate(weeklySchedule?.id);
  };

  const handleCopyLastWeeklySchedule = () => {
    if (!weeklySchedule) {
      alert.error("Weekly schedule ID not found. Please refresh the page.");
      return;
    }
    copyLastWeeklyScheduleMutation.mutate(Number(weeklySchedule?.id));
  };

  const handleOpenShiftRequirementDialog = (shift: ShiftRequirementType) => {
    setShift(shift);
    setIsShiftRequirementDialogOpen(true);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { draggableId, destination } = result;

    const [text, droppedId, dayIndex] = destination.droppableId.split("-");

    // if the dayIndex is list, it means it came from the list of shifts
    if (droppedId === "list") return;

    const shiftType = shiftTypes?.find((s) => s.id === Number(draggableId));
    if (!shiftType || !weeklySchedule?.id) return;

    const date = new Date(weekDates[Number(dayIndex)]);

    const startDateTime = combineDateWithTime(date, shiftType.start_time);
    let endDateTime = combineDateWithTime(date, shiftType.end_time);
    endDateTime = adjustEndTimeByAddingDayIfBeforeStart(
      startDateTime,
      endDateTime
    );

    if (startDateTime < new Date()) {
      alert.error("Cannot create shift in the past");
      return;
    }

    if (text === "role") {
      // if the text is role we are on the positions tab so just create a
      // new shift requirement
      createShiftRequirementMutation.mutate({
        name: shiftType.name,
        department: Number(id),
        weeklySchedule: weeklySchedule?.id,
        scheduleRole: Number(droppedId),
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
        requiredCount: 1,
        filledCount: 0,
      });
    } else if (text === "employee") {
      // check if the employee has a time off request on that day
      const timeOffRequest = timeOffRequests?.find((req) => {
        const startDate = new Date(`${req.start_date}T00:00:00`);
        const endDate = new Date(`${req.end_date}T00:00:00`);
        const currentDate = new Date(date);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        return (
          req.employee?.id === Number(droppedId) &&
          currentDate >= startDate &&
          currentDate <= endDate
        );
      });

      if (timeOffRequest) {
        alert.error(
          `${getFullName(
            timeOffRequest.employee
          )} cannot be scheduled because they have an approved time off request on ${format(
            date,
            "PP"
          )}`
        );
        return;
      }
      // open a dialog to select the employee schedule role when the employee
      // schedule role is saved, check if there is a shift requirement with that
      // schedule role and date, if there is, create a new employee shift
      // with the shift requirement id
      setSelectEmployeeScheduleRoleDetails({
        employeeId: Number(droppedId),
        departmentId: Number(id),
        name: shiftType.name,
        weeklyScheduleId: weeklySchedule?.id,
        startDateTime: startDateTime.toISOString(),
        endDateTime: endDateTime.toISOString(),
      });
      setIsEmployeeRoleDialogOpen(true);
    }
  };

  const handleClearSelectedEmployee = () => {
    setSelectEmployeeScheduleRoleDetails(null);
  };

  const handleOpenTimeOffDialog = (timeOffRequest: TimeOffRequest) => {
    setSelectedTimeOffRequest(timeOffRequest);
    setIsTimeOffRequestDialogOpen(true);
  };

  const handleCloseTimeOffRequestDialog = () => {
    setSelectedTimeOffRequest(null);
  };

  const handleEditShiftType = (shiftType: ShiftType) => {
    setSelectedShiftType(shiftType);
    setIsShiftTypeDialogOpen(true);
  };

  const unpublishedShiftRequirementsExist = useMemo(() => {
    if (!shiftRequirements) return false;

    return shiftRequirements.some(
      (req) => req.status !== ShiftRequirementStatus.Published
    );
  }, [shiftRequirements]);

  const loadingSkeletonArray = useMemo(
    () =>
      Array.from({ length: 8 }).map((_, index) => (
        <Skeleton height={100} width={100} count={4} key={index} />
      )),
    []
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ContentBody>
        <MainContent>
          <DiscussionContainer>
            {shift ? (
              <ShiftRequirementPlanningDialog
                shift={shift}
                setShift={setShift}
                isOpen={isShiftRequirementDialogOpen}
                setIsOpen={setIsShiftRequirementDialogOpen}
              />
            ) : null}
            {selectedTimeOffRequest && (
              <TimeOffRequestDecisionDialog
                timeOffRequest={selectedTimeOffRequest}
                isOpen={isTimeOffRequestDialogOpen}
                setIsOpen={setIsTimeOffRequestDialogOpen}
                onClose={handleCloseTimeOffRequestDialog}
              />
            )}
            <ScheduleCalenderHeader
              weekDates={weekDates}
              onNavigateWeek={handleNavigateWeek}
              onPublishWeeklySchedule={handlePublishWeeklySchedule}
              onCopyLastWeeklySchedule={handleCopyLastWeeklySchedule}
              publishButtonDisabled={
                !unpublishedShiftRequirementsExist ||
                publishWeeklyScheduleMutation.isLoading
              }
              setIsFilterVisible={setIsFilterVisible}
              isFilterVisible={isFilterVisible}
            />
            <div className="px-10 overflow-auto relative">
              <TabNavigation id="calendar">
                <Tab title={"Positions"}>
                  <div className="pb-10 gap-5 grid grid-cols-100px-7fr grid-auto-rows-minmax">
                    <div className="grid sticky top-0 z-10 grid-cols-100px-7fr grid-column-span-full bg-dark-gray">
                      <div className="p-10" />
                      {weekDates.map((date, index) => {
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        const tomorrow = new Date(today);
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        tomorrow.setHours(0, 0, 0, 0);

                        const currentDate = new Date(date);
                        currentDate.setHours(0, 0, 0, 0);

                        return (
                          <div
                            key={index}
                            className="flex p-10 align-center font-w-600"
                            style={{
                              opacity: currentDate < today ? 0.5 : 1,
                              color:
                                currentDate >= today && currentDate < tomorrow
                                  ? "var(--clearpath-yellow)"
                                  : "var(--clearpath-white)",
                            }}
                          >
                            {date.toLocaleDateString("en-US", {
                              weekday: "short",
                            })}{" "}
                            {date.getDate()}
                          </div>
                        );
                      })}
                    </div>

                    {isScheduleRolesLoading ||
                    copyLastWeeklyScheduleMutation.isLoading
                      ? loadingSkeletonArray
                      : null}
                    {!isScheduleRolesLoading &&
                      !copyLastWeeklyScheduleMutation.isLoading &&
                      scheduleRoles &&
                      scheduleRoles?.length > 0 &&
                      scheduleRoles.map((scheduleRole) => (
                        <React.Fragment key={scheduleRole.id}>
                          <div className="font-w-600 align-self-center">
                            {scheduleRole.role.name}
                          </div>
                          {weekDates.map((date, dayIndex) => (
                            <Droppable
                              droppableId={`role-${scheduleRole.id}-${dayIndex}`}
                              key={`${scheduleRole.id}-${dayIndex}`}
                            >
                              {(provided) => (
                                <div
                                  key={`${scheduleRole.id}-${dayIndex}`}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className="bg-cp-black-50 border-light-gray border-radius-5 mh-100 mw-full"
                                >
                                  <div className="flex flex-col gap-5 p-5 h-full w-full">
                                    {isShiftRequirementsLoading ||
                                    createShiftRequirementMutation.isLoading ? (
                                      <Skeleton height={80} />
                                    ) : null}
                                    {!isShiftRequirementsLoading &&
                                    !createShiftRequirementMutation.isLoading &&
                                    shiftRequirements &&
                                    shiftRequirements?.length > 0
                                      ? shiftRequirements
                                          .filter(
                                            (shift) =>
                                              shift.schedule_role ===
                                                scheduleRole.id &&
                                              new Date(
                                                shift.start_datetime
                                              ).getDate() === date.getDate()
                                          )
                                          .map((shift) => (
                                            <ScheduleCalendarBox
                                              key={shift.id}
                                              title={`${shift.filled_count}/${shift.required_count}`}
                                              shift={shift}
                                              onClick={(shift) => {
                                                handleOpenShiftRequirementDialog(
                                                  shift
                                                );
                                              }}
                                            />
                                          ))
                                      : null}
                                    {provided.placeholder}
                                  </div>
                                </div>
                              )}
                            </Droppable>
                          ))}
                        </React.Fragment>
                      ))}
                  </div>
                </Tab>
                <Tab title={"Employees"}>
                  <div className="pb-10 gap-5 grid grid-cols-100px-7fr grid-auto-rows-minmax">
                    <div className="grid sticky top-0 z-10 grid-cols-100px-7fr grid-column-span-full bg-dark-gray">
                      <div className="p-10" />
                      {weekDates.map((date, index) => (
                        <div
                          key={index}
                          className="flex p-10 align-center font-w-600 cp-yellow"
                        >
                          {date.toLocaleDateString("en-US", {
                            weekday: "short",
                          })}{" "}
                          {date.getDate()}
                        </div>
                      ))}
                    </div>
                    {isScheduleRolesLoading ||
                    copyLastWeeklyScheduleMutation.isLoading
                      ? loadingSkeletonArray
                      : null}
                    {!isScheduleRolesLoading &&
                      !copyLastWeeklyScheduleMutation.isLoading &&
                      distinctEmployeeScheduleRoles &&
                      distinctEmployeeScheduleRoles?.length > 0 &&
                      distinctEmployeeScheduleRoles.map(
                        (employeeScheduleRole) => (
                          <React.Fragment
                            key={employeeScheduleRole.employee.id}
                          >
                            <div className="font-w-600 align-self-center">
                              {getFullName(employeeScheduleRole.employee)}
                            </div>
                            {weekDates.map((date, dayIndex) => (
                              <Droppable
                                droppableId={`employee-${employeeScheduleRole.employee.id}-${dayIndex}`}
                                key={`${employeeScheduleRole.employee.id}-${dayIndex}`}
                              >
                                {(provided) => (
                                  <div
                                    key={`${employeeScheduleRole.employee.id}-${dayIndex}`}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="bg-cp-black-50 border-light-gray border-radius-5 mh-100 mw-full"
                                  >
                                    <div className="flex flex-col gap-5 p-5 h-full w-full">
                                      {timeOffRequests &&
                                        timeOffRequests?.length > 0 &&
                                        timeOffRequests
                                          .filter((timeOffRequest) => {
                                            const startDate = new Date(
                                              `${timeOffRequest.start_date}T00:00:00`
                                            );
                                            const endDate = new Date(
                                              `${timeOffRequest.end_date}T00:00:00`
                                            );
                                            const currentDate = new Date(date);
                                            startDate.setHours(0, 0, 0, 0);
                                            endDate.setHours(0, 0, 0, 0);
                                            currentDate.setHours(0, 0, 0, 0);

                                            return (
                                              timeOffRequest?.employee?.id ===
                                                employeeScheduleRole.employee
                                                  .id &&
                                              currentDate >= startDate &&
                                              currentDate <= endDate
                                            );
                                          })
                                          .map((timeOffRequest) => (
                                            <TimeOffBox
                                              key={timeOffRequest.id}
                                              timeOffRequest={timeOffRequest}
                                              onClick={(timeOffRequest) => {
                                                handleOpenTimeOffDialog(
                                                  timeOffRequest
                                                );
                                              }}
                                            />
                                          ))}

                                      {isShiftRequirementsLoading ||
                                      createShiftRequirementMutation.isLoading ? (
                                        <Skeleton height={80} />
                                      ) : null}
                                      {!isShiftRequirementsLoading &&
                                        !createShiftRequirementMutation.isLoading &&
                                        employeeShifts &&
                                        employeeShifts.length > 0 &&
                                        employeeShifts
                                          .filter(
                                            (shift) =>
                                              shift.employee_schedule_role
                                                .employee.id ===
                                                employeeScheduleRole.employee
                                                  .id &&
                                              new Date(
                                                shift.shift_requirement.start_datetime
                                              ).toDateString() ===
                                                date.toDateString()
                                          )
                                          .map((shift) => (
                                            <ShiftRequirementBox
                                              key={shift.id}
                                              title={undefined}
                                              shift={shift.shift_requirement}
                                              onClick={(shift) => {
                                                handleOpenShiftRequirementDialog(
                                                  shift
                                                );
                                              }}
                                            />
                                          ))}
                                      {provided.placeholder}
                                    </div>
                                  </div>
                                )}
                              </Droppable>
                            ))}
                          </React.Fragment>
                        )
                      )}
                  </div>
                </Tab>
              </TabNavigation>
            </div>
            <div className="flex-1" />
            <ScheduleKeyRow />
          </DiscussionContainer>
        </MainContent>
        <RightContent>
          <Card
            className="flex flex-col h-full"
            style={{
              maxHeight: "100vh",
              minHeight: "40vh",
            }}
          >
            <CardHeader
              title="Shifts"
              action={
                <Button
                  className={"icon-button"}
                  onClick={() => setIsShiftTypeDialogOpen(true)}
                >
                  <MdAdd size={24} />
                </Button>
              }
            />
            <div className="flex flex-1 p-10 overflow-y-auto">
              <div className="flex flex-1 flex-col justify-start border-radius-5">
                {!isShiftTypesLoading && shiftTypes?.length === 0 ? (
                  <Placeholder title="No Shifts Found" />
                ) : null}
                {isShiftTypesLoading ? (
                  <>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <div key={index} style={{ marginBottom: "5px" }}>
                        <Skeleton height={50} />
                      </div>
                    ))}
                  </>
                ) : null}
                {!isShiftTypesLoading &&
                shiftTypes &&
                shiftTypes?.length > 0 ? (
                  <Droppable droppableId="shift-list">
                    {(provided) => (
                      <ul
                        className="flex flex-col gap-5"
                        style={{
                          listStyle: "none",
                          overflow: "hidden",
                          paddingLeft: 0,
                          margin: 0,
                          flexGrow: 1,
                        }}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {shiftTypes.map((shift, index) => (
                          <Draggable
                            draggableId={`${shift.id}`}
                            index={index}
                            key={shift.id}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="border-radius-5 p-10 bg-cp-black cursor-move flex-row"
                              >
                                <ListItemText
                                  titleClassName="font-bold"
                                  subTitleClassName="text-xs"
                                  title={shift.name}
                                  subTitle={`${formatTime(
                                    shift.start_time
                                  )} - ${formatTime(shift.end_time)}`}
                                />
                                <div className="flex">
                                  <Button
                                    style={{ all: "unset" }}
                                    onClick={() => handleEditShiftType(shift)}
                                  >
                                    <MdEdit
                                      className="cursor-pointer"
                                      color="var(--muted-text-gray)"
                                    />
                                  </Button>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                ) : null}
              </div>
            </div>
          </Card>
        </RightContent>
        {id ? (
          <ShiftTypeFormDialog
            shiftType={selectedShiftType}
            departmentId={id}
            isOpen={isShiftTypeDialogOpen}
            setIsOpen={setIsShiftTypeDialogOpen}
          />
        ) : null}
        {selectEmployeeScheduleRoleDetails ? (
          <SelectEmployeeScheduleRoleDialog
            isOpen={isEmployeeRoleDialogOpen}
            setIsOpen={setIsEmployeeRoleDialogOpen}
            onClose={handleClearSelectedEmployee}
            details={selectEmployeeScheduleRoleDetails}
            scheduleRoles={scheduleRoles}
          />
        ) : null}
      </ContentBody>
    </DragDropContext>
  );
}
