export enum QueryKeys {
  AnswerOrderChoices = "answerOrderChoices",
  Assessment = "assessment",
  AssessmentsSelectList = "assessmentsSelectList",
  AssessmentsList = "assessmentsList",
  BillingDetails = "billingDetails",
  CategoriesList = "categoriesList",
  CheckSession = "checkSession",
  CommentsUnviewed = "commentsUnviewed",
  Csrf = "csrf",
  DeadlineChoices = "deadlineChoices",
  Department = "department",
  DepartmentsList = "departments",
  DepartmentsWithEmployees = "departmentsWithEmployees",
  DiscussionLinkVideo = "discussionLinkVideo",
  Document = "document",
  DocumentsList = "documentsList",
  DocumentTemplate = "documentTemplate",
  DocumentTemplateList = "documentTemplateList",
  DocumentTemplateAnnotationList = "documentTemplateAnnotationList",
  DocumentTemplateAnnotationOptions = "documentTemplateAnnotationOptions",
  Employee = "employee",
  Employees = "employees",
  EmployeeAISummary = "employeeAISummary",
  EmployeeLearningPath = "employeeLearningPath",
  EmployeesInactive = "employeesInactive",
  EmployeeLearningPathRank = "employeeLearningPathRank",
  EmployeeLPDetails = "employeeLPDetails",
  EmployeeModule = "employeeModule",
  EmployeeModuleContent = "employeeModuleContent",
  EmployeeModuleContents = "employeeModuleContents",
  EmployeeModuleRanks = "employeeModuleRanks",
  EmployeeScheduleRolesList = "employeeScheduleRolesList",
  EmployeeShiftsList = "employeeShiftsList",
  EmployeeSittingsCategories = "employeeSittingsCategories",
  EmployeeBarGraphs = "employeeBarGraphs",
  EmployeeKanban = "employeeKanban",
  RecentSittingsList = "recentSittingsList",
  GetSitting = "getSitting",
  GetSittingsScatterChartData = "getSittingsScatterChartData",
  GetSittingTakeData = "getSittingTakeData",
  InviteToken = "inviteToken",
  LaborBudgets = "laborBudgets",
  LaborBudgetSettings = "laborBudgetSettings",
  LearningPath = "learningPath",
  LearningPathBuilder = "learningPathBuilder",
  LearningPathsList = "learningPathsList",
  LearningPathsActivityCards = "learningPathsActivityCards",
  LearningPathsStats = "learningPathsStats",
  LoginsList = "loginsList",
  LoginsGraph = "loginsGraph",
  Memo = "memo",
  MemoComments = "memoComments",
  MemoCommentReplies = "memoCommentReplies",
  MemosList = "memosList",
  MemosAndPolicies = "memosAndPolicies",
  MemosPieChart = "memosPieChart",
  ModuleContent = "moduleContent",
  ModuleContents = "moduleContents",
  ModuleContentSkipChoices = "moduleContentSkipChoices",
  ModuleDetail = "moduleDetail",
  Modules = "modules",
  MyLearningPathsDetails = "myLearningPathsDetails",
  MyLearningPathTake = "myLearningPathTake",
  MyLearningPathAssessmentTake = "myLearningPathAssessmentTake",
  MyTodosList = "myTodosList",
  NoteFile = "noteFile",
  ObjectViewableByChartData = "objectViewableByChartData",
  PhoneVerificationStatus = "phoneVerificationStatus",
  PhoneResendPasscode = "phoneResendPasscode",
  PoliciesCategoriesList = "policiesCategoriesList",
  PoliciesList = "policiesList",
  PoliciesListPaginated = "policiesListPaginated",
  Policy = "policy",
  PoliciesPieChart = "policiesPieChart",
  RecurrenceChoices = "recurrenceChoices",
  ScheduleRolesList = "scheduleRolesList",
  SchedulesSetupList = "schedulesSetupList",
  ScheduledTodoDeadlineChoices = "scheduledTodoDeadlineChoices",
  ScheduledTodoRecurrenceChoices = "scheduledTodoRecurrenceChoices",
  ScheduledTodoDetail = "scheduledTodoDetail",
  ScheduledTodosList = "scheduledTodosList",
  Session = "session",
  Settings = "settings",
  ShiftConflictsList = "shiftConflictsList",
  ShiftConflictSettings = "shiftConflictSettings",
  ShiftRequirementsList = "shiftRequirementsList",
  ShiftTransfer = "shiftTransfer",
  ShiftTransfersList = "shiftTransfersList",
  ShiftTypesList = "shiftTypesList",
  SignupsList = "signupsList",
  SignupsGraphData = "signupsGraphData",
  TimeOffRequests = "timeOffRequests",
  TodoCompleteIncompleteStats = "todoCompleteIncompleteStats",
  TodosList = "todosList",
  TodosMemosCompleted = "todosMemosCompleted",
  TodosMemosIncomplete = "todosMemosIncomplete",
  TodosPoliciesCompleted = "todosPoliciesCompleted",
  TodosPoliciesIncomplete = "todosPoliciesIncomplete",
  TodosVideosCompleted = "todosVideosCompleted",
  TodosVideosIncomplete = "todosVideosIncomplete",
  UnreadMemos = "unreadMemos",
  UnreadDiscussions = "unreadDiscussions",
  VideoLikeStatus = "videoLikeStatus",
  VideoSavedStatus = "videoSavedStatus",
  Video = "video",
  Videos = "videos",
  VideoViews = "videoViews",
  VideosByCategories = "categoriesWithVideos",
  WeeklyScheduleGetOrCreate = "weeklyScheduleGetOrCreate",
}
