import axiosClient from "src/features/axios/client";

export type GetOrCreateWeeklyScheduleParams = {
  departmentId: string | number;
  startDate: string;
  endDate: string;
};

export type WeeklyScheduleType = {
  id: number;
  department: number;
  start_date: string;
  end_date: string;
};

class WeeklySchedulesService {
  async copyLastWeeklySchedule(id: number): Promise<{ detail: string }> {
    return await axiosClient
      .post(`/api/weekly-schedules/${id}/copy-last-week/`)
      .then((res) => res.data);
  }
  async getOrCreateWeeklySchedule(
    params: GetOrCreateWeeklyScheduleParams
  ): Promise<WeeklyScheduleType> {
    return await axiosClient
      .get("/api/weekly-schedules/get-or-create/", {
        params: {
          department_id: params.departmentId,
          start_date: params.startDate,
          end_date: params.endDate,
        },
      })
      .then((res) => res.data);
  }
  async getWeeklySchedules(): Promise<WeeklyScheduleType[]> {
    return await axiosClient
      .get("/api/weekly-schedules")
      .then((res) => res.data);
  }

  async publishWeeklySchedule(
    weeklyScheduleId: number
  ): Promise<WeeklyScheduleType> {
    return await axiosClient
      .post(`/api/weekly-schedules/${weeklyScheduleId}/publish/`)
      .then((res) => res.data);
  }
}

const weeklySchedulesService = new WeeklySchedulesService();

export default weeklySchedulesService;
