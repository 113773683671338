import { useAlert } from "@blaumaus/react-alert";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useErrorHandling from "src/features/errors/hooks/useErrorHandling";
import weeklySchedulesService from "../services/weeklySchedulesService";

export default function usePublishWeeklyScheduleMutation() {
  const alert = useAlert();
  const navigate = useNavigate();
  const handleErrors = useErrorHandling();

  return useMutation({
    mutationFn: async (weeklyScheduleId: number) =>
      weeklySchedulesService.publishWeeklySchedule(weeklyScheduleId),
    onSuccess: () => {
      alert.success("Weekly schedule published successfully!");
      navigate("/schedules");
    },
    onError: handleErrors,
  });
}
