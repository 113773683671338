import { useAlert } from "@blaumaus/react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";

import Button from "src/components/Buttons/Button";
import Card from "src/components/Cards/Card";

import {
  ButtonColors,
  ButtonSizes,
} from "src/components/Buttons/buttons.types";
import ButtonsContainer from "src/components/Container/ButtonsContainer";
import getFileTypeFromAWSLink from "src/features/aws/utils/getFileTypeFromAWSLink";
import useScrollIntoView from "src/features/page/hooks/useScrollIntoView";
import ProgressBarTimer from "src/features/progress/components/ProgressBarTimer";
import { QueryKeys } from "src/features/reactQuery/types/queryKeys.types";
import { PATH_LEARNING_PATHS } from "src/features/site/routes";
import VideoPlayer from "src/features/videos/components/VideoPlayer";
import useVideoActions from "src/features/videos/hooks/useVideoActions";
import createTimeTriggerAction from "src/features/videos/utils/createTimeTriggerAction";
import AssessmentTakeInfo from "../../../assessments/components/AssessmentTakeInfo";
import MCQuestion from "../../../assessments/components/MCQuestion";
import { useCompletionStatus } from "../../hooks/useCompletionStatus";
import { useContentType } from "../../hooks/useContentType";
import useGetLearningPathSittingQuestion from "../../hooks/useGetLearningPathSittingQuestion";
import useGetMyLearningPathTake from "../../hooks/useGetMyLearningPathTake";
import useLpMcQuestionMutation from "../../hooks/useLpMcQuestionMutation";
import useLpNumericQuestionMutation from "../../hooks/useLpNumericQuestionMutation";
import useMyLPTakeMutation from "../../hooks/useMyLPTakeMutation";
import { CanEmployeeSkipChoices } from "../../learningPaths.enums";
import LearningPathTakeCompleted from "./LearningPathTakeCompleted";
import LPAssessmentResult from "./LPAssessmentResult";
import LPImage from "./LPImage";
import LPPDFViewer from "./LPPDFViewer";
import ModuleContentTitleAndDescription from "./ModuleContentTitleAndDescription";
import LPNumericQuestion from "./NumericQuestion";
import PreviousQuestion from "./PreviousQuestion";

const CARD_STYLE = {
  display: "flex",
  flexDirection: "column",
  padding: "15px",
  gap: "10px",
} as React.CSSProperties;

export default function LearningPathTake() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoLengthTriggered, setVideoLengthTriggered] = useState(false);
  const alert = useAlert();
  const queryClient = useQueryClient();

  const myLearningPathTakeMutation = useMyLPTakeMutation();
  const lpMcQuestionMutation = useLpMcQuestionMutation();
  const lpNumericQuestionMutation = useLpNumericQuestionMutation();

  const { data: myLPMc, isLoading: myLPMcIsLoading } = useGetMyLearningPathTake(
    { id }
  );

  const { module_content: moduleContent = {} } = myLPMc || {};

  const {
    id: mcId = null,
    can_employee_skip = null,
    content_object = {},
    pdf_page_range = {},
  } = moduleContent || {};

  const { start_page: startPage = null, end_page: endPage = null } =
    pdf_page_range || {};

  const {
    id: contentObjectId = null,
    title = null,
    description = null,
    time_limit: timeLimit = null,
    graded = null,
    passing_percentage: passingPercentage = null,
    single_attempt: singleAttempt = null,
    file_url = null,
    video_file: videoFile = null,
  } = content_object;

  const isAssessment = useContentType(moduleContent, "assessment");
  const isMCFile = useContentType(moduleContent, "modulecontentfile");
  const isVideo = useContentType(moduleContent, "video");
  const isPolicy = useContentType(moduleContent, "policy");

  const {
    data: assessmentTakeData,
    refetch: refetchQuestion,
    isInitialLoading: assessmentTakeLoading,
  } = useGetLearningPathSittingQuestion({
    emcId: myLPMc?.id ? myLPMc.id : undefined,
    id: contentObjectId ? contentObjectId : undefined,
    enabled: false,
  });

  const scrollRef = useScrollIntoView(assessmentTakeData || moduleContent);

  const { handleSubmit, control, reset, register, watch } = useForm();

  const selectedAnswers = watch("selectedAnswers");
  const numericAnswer = watch("answer");

  const handleToggleAnswer = (selectedAnswers: any, answerId: any) => {
    let answers;
    if (selectedAnswers.includes(answerId)) {
      answers = selectedAnswers.filter((id: number) => id !== answerId);
    } else {
      if (assessmentTakeData.question.correct_answer_count === 1) {
        answers = [answerId];
      } else {
        answers = [...selectedAnswers, answerId];
      }
    }
    return answers;
  };

  const contentLoading = useMemo(() => {
    return (
      myLPMcIsLoading ||
      lpMcQuestionMutation.isLoading ||
      lpNumericQuestionMutation.isLoading ||
      myLearningPathTakeMutation.isLoading ||
      assessmentTakeLoading
    );
  }, [
    myLPMcIsLoading,
    lpMcQuestionMutation.isLoading,
    lpNumericQuestionMutation.isLoading,
    myLearningPathTakeMutation.isLoading,
    assessmentTakeLoading,
  ]);

  const isAssessmentCompleted = useCompletionStatus(assessmentTakeData);
  const isModuleContentCompleted = useCompletionStatus(myLPMc);

  const fileUrl = isMCFile
    ? file_url
    : isVideo
    ? videoFile
    : assessmentTakeData?.question?.file_url
    ? assessmentTakeData?.question?.file_url
    : null;

  const isVideoFile = getFileTypeFromAWSLink(fileUrl) === "video";
  const isImageFile = getFileTypeFromAWSLink(fileUrl) === "image";
  const isPdfFile = getFileTypeFromAWSLink(file_url) === "policy";

  const handleRetakeAssessment = () => refetchQuestion();

  const handleNext = (e: any) => {
    e.preventDefault();

    if (!mcId || !id) return;

    if (isAssessment && !assessmentTakeData) {
      refetchQuestion();
      return;
    } else if (isAssessment && !isAssessmentCompleted) {
      handleSubmit(onSubmitAsessmentAnswer)();
      return;
    }

    myLearningPathTakeMutation.mutate({
      id: id,
      emcId: myLPMc?.id,
    });
  };

  const onSubmitAsessmentAnswer = (data: any) => {
    if (!mcId || !id) return;
    const { selectedAnswers, answer } = data;

    if (selectedAnswers) {
      lpMcQuestionMutation.mutate({
        id: contentObjectId,
        answers: selectedAnswers,
        emcId: myLPMc?.id,
      });
    } else if (answer) {
      lpNumericQuestionMutation.mutate({
        id: contentObjectId,
        answer: answer,
        emcId: myLPMc?.id,
      });
    }

    reset();
  };

  const handleGoToMyLearningPaths = () => {
    queryClient.removeQueries([QueryKeys.MyLearningPathAssessmentTake]);
    queryClient.removeQueries([QueryKeys.MyLearningPathTake]);
    navigate(`/${PATH_LEARNING_PATHS}/${myLPMc.id}`);
  };

  const videoWatchedTrigger = () => {
    alert.success("Congratulations! This video has been marked as complete.");
    setNextBtnDisabled(false);
  };

  const timeUpdateAction = createTimeTriggerAction(
    videoRef,
    videoWatchedTrigger,
    videoLengthTriggered,
    setVideoLengthTriggered
  );

  useVideoActions(videoRef, {
    timeupdate: timeUpdateAction,
  });

  useEffect(() => {
    if (isVideoFile) {
      setNextBtnDisabled(true);
      setVideoLengthTriggered(false);
    }
  }, [isVideoFile]);

  useEffect(() => {
    if (can_employee_skip === CanEmployeeSkipChoices.ALWAYS) {
      setNextBtnDisabled(false);
    }
  }, [can_employee_skip]);

  const showTakeCompleted = !contentLoading && isModuleContentCompleted;
  const showMcTitle = !contentLoading;
  const showAssessmentInfo =
    !contentLoading &&
    isAssessment &&
    !assessmentTakeData &&
    !isMCFile &&
    !isVideoFile &&
    !isAssessmentCompleted;
  const showProgressBar =
    !contentLoading &&
    isAssessment &&
    assessmentTakeData &&
    !isAssessmentCompleted;
  const showPreviousQuestion =
    !contentLoading && isAssessment && assessmentTakeData?.previous_question;
  const showVideoPlayer = !contentLoading && isVideoFile;
  const showImage = !contentLoading && isImageFile;
  const showMcQuestion =
    !contentLoading &&
    isAssessment &&
    assessmentTakeData?.question?.answers &&
    !isAssessmentCompleted;
  const showNumericQuestion =
    !contentLoading &&
    isAssessment &&
    assessmentTakeData?.question &&
    !assessmentTakeData?.question?.answers &&
    !isAssessmentCompleted;
  const showAssessmentResult =
    isAssessmentCompleted && !isModuleContentCompleted;
  const showPdfViewer = !contentLoading && (isPolicy || isPdfFile);
  const showBackBtn = !contentLoading && isModuleContentCompleted;
  const showNextBtn = !contentLoading && !isModuleContentCompleted;

  const isNextBtnDisabled =
    nextBtnDisabled ||
    contentLoading ||
    (isAssessment &&
      assessmentTakeData?.question &&
      !isAssessmentCompleted &&
      (selectedAnswers === undefined || selectedAnswers?.length === 0) &&
      numericAnswer === undefined);

  return (
    <Card style={CARD_STYLE}>
      {contentLoading ? <Skeleton height={300} /> : null}
      {(isAssessmentCompleted &&
        assessmentTakeData?.passed &&
        !singleAttempt &&
        showNextBtn) ||
      (!contentLoading &&
        isAssessment &&
        isAssessmentCompleted &&
        singleAttempt) ||
      (!contentLoading && isAssessment && !isAssessmentCompleted) ||
      (!isAssessment && showNextBtn) ? (
        <div className="text-center border-danger bg-cp-black-50 border-radius-5 p-20">
          <span className="text-lg font-bold text-danger">
            Note: Section is not complete until you click "Next"
          </span>
        </div>
      ) : null}
      {showTakeCompleted ? <LearningPathTakeCompleted /> : null}
      {showProgressBar ? (
        <ProgressBarTimer
          timeLimit={timeLimit}
          startTime={assessmentTakeData?.sitting?.start}
          currentTime={assessmentTakeData?.sitting?.current_time}
          currentQuestionNum={assessmentTakeData?.sitting?.current_question}
          totalQuestions={assessmentTakeData?.sitting?.total_questions}
          onTimeOutCallback={refetchQuestion}
        />
      ) : null}
      {showPreviousQuestion ? (
        <PreviousQuestion
          outcome={assessmentTakeData.previous_outcome}
          question={assessmentTakeData.previous_question}
          answers={assessmentTakeData.previous_answers}
        />
      ) : null}
      {showMcTitle ? (
        <ModuleContentTitleAndDescription
          title={contentLoading ? <Skeleton width={200} /> : title}
          description={contentLoading ? <Skeleton width={200} /> : description}
        />
      ) : null}
      {showAssessmentInfo ? (
        <AssessmentTakeInfo
          title={title}
          description={description}
          timeLimit={timeLimit}
          singleAttempt={singleAttempt}
          graded={graded}
          passingPercentage={passingPercentage}
        />
      ) : null}
      {showVideoPlayer ? (
        <VideoPlayer src={fileUrl} ref={videoRef} autoPlay={true} controls />
      ) : null}
      {showImage ? <LPImage src={fileUrl} /> : null}
      {showMcQuestion ? (
        <MCQuestion
          question={assessmentTakeData.question}
          isLoading={assessmentTakeLoading}
          control={control}
          onToggleAnswer={handleToggleAnswer}
        />
      ) : null}
      {showNumericQuestion ? (
        <LPNumericQuestion
          question={assessmentTakeData.question}
          register={register}
        />
      ) : null}
      {showAssessmentResult ? (
        <>
          <LPAssessmentResult sittingId={assessmentTakeData?.sitting_id} />
        </>
      ) : null}
      {showPdfViewer ? (
        <LPPDFViewer
          fileUrl={file_url}
          startPage={startPage}
          endPage={endPage}
          pageWidth={300}
          onLastPage={setNextBtnDisabled}
        />
      ) : null}
      <ButtonsContainer>
        {contentLoading ? <Skeleton width={150} height={40} /> : null}
        {showBackBtn ? (
          <Button
            color={ButtonColors.Yellow}
            size={ButtonSizes.LG}
            title="Go back to My Learning Paths"
            onClick={handleGoToMyLearningPaths}
          />
        ) : null}
        {!contentLoading &&
        isAssessment &&
        isAssessmentCompleted &&
        !singleAttempt ? (
          <Button
            color={ButtonColors.GrayAndYellow}
            size={ButtonSizes.LG}
            title="Retake Assessment"
            onClick={handleRetakeAssessment}
          />
        ) : null}
        {(isAssessmentCompleted &&
          assessmentTakeData?.passed &&
          !singleAttempt &&
          showNextBtn) ||
        (!contentLoading &&
          isAssessment &&
          isAssessmentCompleted &&
          singleAttempt) ||
        (!contentLoading && isAssessment && !isAssessmentCompleted) ||
        (!isAssessment && showNextBtn) ? (
          <Button
            color={ButtonColors.Yellow}
            size={ButtonSizes.LG}
            title="Next"
            type="button"
            onClick={handleNext}
            disabled={isNextBtnDisabled}
          />
        ) : null}
        <div ref={scrollRef} />
      </ButtonsContainer>
    </Card>
  );
}
